/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {Stack, Banner, BodyText} from "@servicetitan/design-system";
import {ImageBlock} from "components";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    h2: "h2",
    p: "p",
    code: "code",
    h3: "h3",
    pre: "pre",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  if (!Stack) _missingMdxReference("Stack", false);
  if (!Stack.Item) _missingMdxReference("Stack.Item", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "design-spec",
    style: {
      position: "relative"
    }
  }, "Design Spec", React.createElement(_components.a, {
    href: "#design-spec",
    "aria-label": "design spec permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.h2, {
    id: "form-anatomy",
    style: {
      position: "relative"
    }
  }, "Form Anatomy", React.createElement(_components.a, {
    href: "#form-anatomy",
    "aria-label": "form anatomy permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Forms are made up of many possible components, including many represented below."), "\n", React.createElement(Stack, {
    spacing: 3
  }, React.createElement(Stack.Item, {
    grow: 4,
    basis: 0
  }, React.createElement(ImageBlock, {
    src: "/images/form/form_anatomy.png",
    noPadding: true,
    customBackground: true
  })), React.createElement(Stack.Item, {
    grow: 3,
    basis: 0
  }, React.createElement(_components.div, {
    className: "p-4"
  }, React.createElement(_components.div, null, "1. Label"), React.createElement(_components.div, null, "2. Inline Help"), React.createElement(_components.div, null, "3. Overlay Help"), React.createElement(_components.div, null, "4. Placeholder"), React.createElement(_components.div, null, "5. Character Counter"), React.createElement(_components.div, null, "6. Form Actions")))), "\n", React.createElement(_components.h2, {
    id: "spacing",
    style: {
      position: "relative"
    }
  }, "Spacing", React.createElement(_components.a, {
    href: "#spacing",
    "aria-label": "spacing permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Spacing between form element is 24px, or ", React.createElement(_components.code, null, "$spacing-3"), " on our token scale. This is true for both horizontal and vertical scrolling. Certain elements, such as a group of radios or checkboxes, are a more compact 16px (", React.createElement(_components.code, null, "$spacing-2"), "). Spacing within elements of form elements are typically 8px, or ", React.createElement(_components.code, null, "$spacing-1"), " on our ", React.createElement(_components.a, {
    href: "/foundations/spacing/#spacing"
  }, "token scale"), "."), "\n", React.createElement(Stack, {
    spacing: 3
  }, React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(ImageBlock, {
    src: "/images/form/form_spacing_1.png"
  })), React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(ImageBlock, {
    src: "/images/form/form_spacing_2.png"
  }))), "\n", React.createElement(_components.p, null, "}"), "\n", React.createElement(_components.h2, {
    id: "grouping",
    style: {
      position: "relative"
    }
  }, "Grouping", React.createElement(_components.a, {
    href: "#grouping",
    "aria-label": "grouping permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Spacing between the form element group is 48px, or ", React.createElement(_components.code, null, "$spacing-5"), "."), "\n", React.createElement(Stack, {
    spacing: 3
  }, React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(ImageBlock, {
    src: "/images/form/form_grouping_1.png"
  })), React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(ImageBlock, {
    src: "/images/form/form_grouping_2.png"
  }))), "\n", React.createElement(_components.p, null, "}"), "\n", React.createElement(_components.h2, {
    id: "divider",
    style: {
      position: "relative"
    }
  }, "Divider", React.createElement(_components.a, {
    href: "#divider",
    "aria-label": "divider permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Dividers can be used to group out form elements, particularly when there is no subheader copy needed. This would use the ", React.createElement(_components.a, {
    href: "/components/divider"
  }, "divider component"), " to accomplish this."), "\n", React.createElement(Stack, {
    spacing: 3
  }, React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(ImageBlock, {
    src: "/images/form/form_divider_1.png"
  })), React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(ImageBlock, {
    src: "/images/form/form_divider_2.png"
  }))), "\n", React.createElement(_components.h2, {
    id: "dividers--sub-headers",
    style: {
      position: "relative"
    }
  }, "Dividers + Sub Headers", React.createElement(_components.a, {
    href: "#dividers--sub-headers",
    "aria-label": "dividers  sub headers permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Subheaders and dividers can be combined on denser UI pages where separation is important to reinforce, while also needing the subheader copy."), "\n", React.createElement(Stack, {
    spacing: 3
  }, React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(ImageBlock, {
    src: "/images/form/form_dividers_sub_headers_1.png"
  })), React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(ImageBlock, {
    src: "/images/form/form_dividers_sub_headers_2.png"
  }))), "\n", React.createElement(_components.h2, {
    id: "columns",
    style: {
      position: "relative"
    }
  }, "Columns", React.createElement(_components.a, {
    href: "#columns",
    "aria-label": "columns permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(Stack, {
    spacing: 3
  }, React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(ImageBlock, {
    src: "/images/form/form_columns_1.png"
  })), React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, "As a rule, form elements should be limited to 1 column. Multi-column layouts can be used when there is sufficient space on the screen for it, and the elements are logically related to each other.")), "\n", React.createElement(Stack, {
    spacing: 3,
    className: "m-t-4"
  }, React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(ImageBlock, {
    src: "/images/form/form_columns_2.png"
  })), React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  })), "\n", React.createElement(Stack, {
    spacing: 3,
    className: "m-t-4"
  }, React.createElement(Stack.Item, {
    grow: 2,
    basis: 0
  }, React.createElement(ImageBlock, {
    src: "/images/form/form_columns_3.png",
    suggest: "dont"
  }), React.createElement(Banner, {
    status: "critical",
    title: "Don't"
  }, "Avoid creating columns of only semi-related items.")), React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  })), "\n", React.createElement(_components.h2, {
    id: "forms-in-layouts",
    style: {
      position: "relative"
    }
  }, "Forms in Layouts", React.createElement(_components.a, {
    href: "#forms-in-layouts",
    "aria-label": "forms in layouts permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.h3, {
    id: "in-a-drawer",
    style: {
      position: "relative"
    }
  }, "In a Drawer", React.createElement(_components.a, {
    href: "#in-a-drawer",
    "aria-label": "in a drawer permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "As a rule, form element width should conform to the size of the Drawer’s set width."), "\n", React.createElement(ImageBlock, {
    src: "/images/form/form_in_drawer.png",
    customBackground: true,
    noPadding: true
  }), "\n", React.createElement(_components.h3, {
    id: "in-a-modal",
    style: {
      position: "relative"
    }
  }, "In a Modal", React.createElement(_components.a, {
    href: "#in-a-modal",
    "aria-label": "in a modal permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "As a rule, form element width should conform to the size of the Modal’s set width."), "\n", React.createElement(ImageBlock, {
    src: "/images/form/form_in_modal.png",
    customBackground: true,
    noPadding: true
  }), "\n", React.createElement(_components.h3, {
    id: "in-a-flow-card",
    style: {
      position: "relative"
    }
  }, "In a Flow Card", React.createElement(_components.a, {
    href: "#in-a-flow-card",
    "aria-label": "in a flow card permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "As a rule, form element widths in Flow Cards are set to a logical width to the form element, rather than the size of the Flow Card."), "\n", React.createElement(ImageBlock, {
    src: "/images/form/form_in_flow_card.png"
  }), "\n", React.createElement(_components.h3, {
    id: "on-a-settings-page",
    style: {
      position: "relative"
    }
  }, "On a settings page", React.createElement(_components.a, {
    href: "#on-a-settings-page",
    "aria-label": "on a settings page permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Refer to the ", React.createElement(_components.a, {
    href: "https://www.figma.com/file/AbAAPLF88iC4fxoLYSdeCH/Settings-Templates-%26-Examples?node-id=1672%3A49719"
  }, "Settings Templates and Examples"), " for more specific examples of Form usage in this layout."), "\n", React.createElement(ImageBlock, {
    src: "/images/form/form_in_settings.png"
  }), "\n", React.createElement(_components.h1, {
    id: "usage-guidelines",
    style: {
      position: "relative"
    }
  }, "Usage Guidelines", React.createElement(_components.a, {
    href: "#usage-guidelines",
    "aria-label": "usage guidelines permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.h2, {
    id: "labels-and-help",
    style: {
      position: "relative"
    }
  }, "Labels and Help", React.createElement(_components.a, {
    href: "#labels-and-help",
    "aria-label": "labels and help permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.h3, {
    id: "what-help-elements-should-we-use",
    style: {
      position: "relative"
    }
  }, "What help elements should we use?", React.createElement(_components.a, {
    href: "#what-help-elements-should-we-use",
    "aria-label": "what help elements should we use permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "inline: true\ncustomRender: true\n---\nconst browsers = [\n\t{\n\t\telement: \"Label\",\n\t\twhen: \"In most situations. Provides essential information to a user in 1–3 words.\",\n\t\tpreference: \"1st\",\n\t},\n\t{\n\t\telement: \"Inline Help\",\n\t\twhen: \"When needing to provide additional context to a field in 1–3 sentences.\",\n\t\tpreference: \"2nd\",\n\t},\n\t{\n\t\telement: \"Overlay Help\",\n\t\twhen: \"When providing supplemental information, or lengthy explanations.\",\n\t\tpreference: \"3rd\",\n\t},\n\t{\n\t\telement: \"Placeholder\",\n\t\twhen: \"In general, avoid. May be used to hint at text field’s formatting.\",\n\t\tpreference: \"4th\",\n\t},\n]\n\nrender(\n\t<DataList\n\t\tclassName=\"DocsTable\"\n\t\theader={[\n\t\t\t<DataList.HeaderCell key={0} content=\"Element\" />,\n\t\t\t<DataList.HeaderCell key={1} content=\"When to use\" />,\n\t\t\t<DataList.HeaderCell key={2} content=\"Preference\" />,\n\t\t]}\n\t>\n\t\t{browsers.map((row, index) => {\n\t\t\treturn (\n\t\t\t\t<DataList.Row key={index}>\n\t\t\t\t\t<DataList.Cell width=\"130px\">{row.element}</DataList.Cell>\n\t\t\t\t\t<DataList.Cell>{row.when}</DataList.Cell>\n\t\t\t\t\t<DataList.Cell width=\"110px\">\n\t\t\t\t\t\t{row.preference}\n\t\t\t\t\t</DataList.Cell>\n\t\t\t\t</DataList.Row>\n\t\t\t)\n\t\t})}\n\t</DataList>\n)\n")), "\n", React.createElement(Stack, {
    spacing: 3,
    className: "m-t-4"
  }, React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(_components.h3, null, "Labels should almost always be used"), React.createElement(BodyText, null, "Most contexts for forms require a label. Some exceptions include when an icon can clearly explain the use case, such as a search input, or if a label exists elsewhere, such as a table column.")), React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(ImageBlock, {
    src: "/images/form/form_label_use_do.png",
    suggest: "do"
  }), React.createElement(Banner, {
    status: "success",
    title: "Do",
    className: "m-b-4"
  }), React.createElement(ImageBlock, {
    src: "/images/form/form_label_use_dont.png",
    suggest: "dont"
  }), React.createElement(Banner, {
    status: "critical",
    title: "Don't"
  }, "Placeholders have significant usability issues, and generally should not replace a label."))), "\n", React.createElement(Stack, {
    spacing: 3,
    className: "m-t-4"
  }, React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(_components.h3, null, "Use inline help for essential and supplementary information"), React.createElement(BodyText, null, "Inline help is strong at providing additional information necessary to complete a form.")), React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(Stack, {
    spacing: 4,
    direction: "column"
  }, React.createElement(ImageBlock, {
    src: "/images/form/form_inline_password.png"
  }), React.createElement(ImageBlock, {
    src: "/images/form/form_inline_learn_more.png"
  })))), "\n", React.createElement(Stack, {
    spacing: 3,
    className: "m-t-4"
  }, React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(_components.h3, null, "Use overlay help for supplementary information"), React.createElement(BodyText, null, "Overlay help can de-clutter a page by putting less important, supplemental information behind an overlay element.")), React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(Stack, {
    spacing: 4,
    direction: "column"
  }, React.createElement(ImageBlock, {
    src: "/images/form/form_overlay_supplementary_1.png"
  }), React.createElement(ImageBlock, {
    src: "/images/form/form_overlay_supplementary_2.png"
  })))), "\n", React.createElement(Stack, {
    spacing: 3,
    className: "m-t-4"
  }, React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(_components.h3, null, "Inline help is preferred over overlay help"), React.createElement(BodyText, null, "Inline help always being visible on the page improves the usability of the content itself. Overlay help can still be used however, particularly when the content density of the page is high.")), React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  })), "\n", React.createElement(Stack, {
    spacing: 3,
    className: "m-t-4"
  }, React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(_components.h3, null, "With overlay help, use the ? icon over the i icon"), React.createElement(BodyText, null, "The information icon (i) is harder to read on low pixel-density monitors.")), React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(ImageBlock, {
    src: "/images/form/form_use_q_over_i.png",
    suggest: "dont"
  }), React.createElement(Banner, {
    status: "critical",
    title: "Don't"
  }))), "\n", React.createElement(Stack, {
    spacing: 3,
    className: "m-t-4"
  }, React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(_components.h3, null, "With overlay help, the ? icon is preferred to dashed underline"), React.createElement(BodyText, null, "Using the dashed line can create usability issues. This style should only be used in heavy form pages where most form elements offer supplemental help.")), React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(ImageBlock, {
    src: "/images/form/form_use_q_over_dots.png",
    suggest: "caution"
  }), React.createElement(Banner, {
    status: "warning",
    title: "Caution"
  }))), "\n", React.createElement(Stack, {
    spacing: 3,
    className: "m-t-4"
  }, React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(_components.h3, null, "Placeholder text should generally be avoided"), React.createElement(BodyText, {
    el: "div"
  }, "In general, placeholders should be omitted, as they have many downsides:", React.createElement(_components.ul, null, React.createElement(_components.li, null, "Users lose the help content on typing, and after the element is filled out."), React.createElement(_components.li, null, "Higher contrast text can confuse a user into believing the placeholder is a real value."), React.createElement(_components.li, null, "They have inconsistent implementations in browsers.")))), React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(ImageBlock, {
    src: "/images/form/form_placeholder_search.png"
  }), React.createElement(BodyText, {
    size: "small",
    className: "m-x-1 m-t-half"
  }, "Input fields such as search, when paired with the icon, can be sufficient."), React.createElement(Stack, {
    className: "m-t-4",
    direction: "column"
  }, React.createElement(ImageBlock, {
    src: "/images/form/form_placeholder_avoid.png",
    suggest: "dont"
  }), React.createElement(Banner, {
    status: "critical",
    title: "Don't"
  }, "Most icons do not have a universal understanding to replace a label.")))), "\n", React.createElement(_components.h3, {
    id: "reassure-the-user-as-they-take-action",
    style: {
      position: "relative"
    }
  }, "Reassure the user as they take action", React.createElement(_components.a, {
    href: "#reassure-the-user-as-they-take-action",
    "aria-label": "reassure the user as they take action permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Form help reminds and validates the user’s actions. It’s especially valuable when used with features that aren’t used often, have high stakes, or rely on a certain level of expertise and area knowledge, e.g., accounting concepts."), "\n", React.createElement(_components.p, null, "This helps the user feel confident about the outcome what they are about to do and reduce errors."), "\n", React.createElement(_components.h2, {
    id: "form-validation",
    style: {
      position: "relative"
    }
  }, "Form Validation", React.createElement(_components.a, {
    href: "#form-validation",
    "aria-label": "form validation permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "To see how to handle errors in Forms, refer to our ", React.createElement(_components.a, {
    href: "/patterns/errors/"
  }, "Errors & Warning pattern documentation"), "."), "\n", React.createElement(Stack, {
    spacing: 3
  }, React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(ImageBlock, {
    src: "/images/form/form_pattern_error.png"
  })), React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  })), "\n", React.createElement(_components.h2, {
    id: "progressive-disclosure",
    style: {
      position: "relative"
    }
  }, "Progressive Disclosure", React.createElement(_components.a, {
    href: "#progressive-disclosure",
    "aria-label": "progressive disclosure permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Certain form elements can be displayed only after a relevant element has been selected. This can be useful in shortening a form without losing critical information. When these hidden elements are inline, indenting the element can visually reinforce its relation."), "\n", React.createElement(Stack, {
    spacing: 3
  }, React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(ImageBlock, {
    src: "/images/form/form_progressive_1.png"
  })), React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(ImageBlock, {
    src: "/images/form/form_progressive_2.png"
  }))), "\n", React.createElement(_components.h2, {
    id: "button-alignment",
    style: {
      position: "relative"
    }
  }, "Button Alignment", React.createElement(_components.a, {
    href: "#button-alignment",
    "aria-label": "button alignment permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.h3, {
    id: "place-form-actions-at-the-bottom",
    style: {
      position: "relative"
    }
  }, "Place form actions at the bottom", React.createElement(_components.a, {
    href: "#place-form-actions-at-the-bottom",
    "aria-label": "place form actions at the bottom permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(Stack, {
    spacing: 3
  }, React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(ImageBlock, {
    src: "/images/form/form_button_bottom.png",
    suggest: "do"
  }), React.createElement(Banner, {
    status: "success",
    title: "Do"
  })), React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(ImageBlock, {
    src: "/images/form/form_button_top.png",
    suggest: "dont"
  }), React.createElement(Banner, {
    status: "critical",
    title: "Don't"
  }))), "\n", React.createElement(Stack, {
    spacing: 3,
    className: "m-t-4"
  }, React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(_components.h3, null, "Left alignment usage"), React.createElement(BodyText, null, "Left alignment is used when the form is on a typical page.")), React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(ImageBlock, {
    src: "/images/form/form_button_left.png"
  }))), "\n", React.createElement(Stack, {
    spacing: 3,
    className: "m-t-4"
  }, React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(_components.h3, null, "Right alignment usage"), React.createElement(BodyText, null, "Right-aligned actions occur when the form element is contained in a Modal or Drawer, or when a page has a sticky footer.")), React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(Stack, {
    spacing: 4,
    direction: "column"
  }, React.createElement(ImageBlock, {
    src: "/images/form/form_button_right_modal.png"
  }), React.createElement(ImageBlock, {
    src: "/images/form/form_button_right_drawer.png"
  }), React.createElement(ImageBlock, {
    src: "/images/form/form_button_right_page.png"
  })))), "\n", React.createElement(_components.h1, {
    id: "content-guidelines",
    style: {
      position: "relative"
    }
  }, "Content Guidelines", React.createElement(_components.a, {
    href: "#content-guidelines",
    "aria-label": "content guidelines permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.h2, {
    id: "form-titles",
    style: {
      position: "relative"
    }
  }, "Form Titles", React.createElement(_components.a, {
    href: "#form-titles",
    "aria-label": "form titles permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Titles should clearly communicate at a glance what the purpose of the form is."), "\n", React.createElement(Stack, {
    spacing: 3
  }, React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(ImageBlock, {
    suggest: "do"
  }, React.createElement(BodyText, null, "Job Dashboard"), React.createElement(BodyText, null, "Agreement Details"), React.createElement(BodyText, null, "Visits")), React.createElement(Banner, {
    status: "success",
    title: "Do"
  })), React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(ImageBlock, {
    suggest: "dont"
  }, React.createElement(BodyText, null, "General Info"), React.createElement(BodyText, null, "Details"), React.createElement(BodyText, null, "Other")), React.createElement(Banner, {
    status: "critical",
    title: "Don't"
  }))), "\n", React.createElement(_components.h2, {
    id: "labels",
    style: {
      position: "relative"
    }
  }, "Labels", React.createElement(_components.a, {
    href: "#labels",
    "aria-label": "labels permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "A label is a short, meaningful description that clearly indicates what the user is expected to enter."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Labels should be 1 to 3 words and written in title case."), "\n", React.createElement(_components.li, null, "Labels should primarily be nouns. Avoid using labels as CTAs."), "\n", React.createElement(_components.li, null, "A label is not inline help and shouldn't be used to provide instruction."), "\n"), "\n", React.createElement(Stack, {
    spacing: 3
  }, React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(ImageBlock, {
    suggest: "do"
  }, React.createElement(BodyText, null, "Email Address"), React.createElement(BodyText, null, "Phone Number"), React.createElement(BodyText, null, "First Name")), React.createElement(Banner, {
    status: "success",
    title: "Do"
  })), React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(ImageBlock, {
    suggest: "dont"
  }, React.createElement(BodyText, null, "What is your email address?"), React.createElement(BodyText, null, "My phone number is:"), React.createElement(BodyText, null, "First name")), React.createElement(Banner, {
    status: "critical",
    title: "Don't"
  }))), "\n", React.createElement(_components.h2, {
    id: "optional-vs-required-labeling",
    style: {
      position: "relative"
    }
  }, "Optional vs Required Labeling", React.createElement(_components.a, {
    href: "#optional-vs-required-labeling",
    "aria-label": "optional vs required labeling permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(Stack, {
    spacing: 3,
    className: "m-t-4"
  }, React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(BodyText, {
    el: "div"
  }, "Form elements can be additionally labeled as required (*), or optional (optional). The way to use these includes:", React.createElement(_components.ul, null, React.createElement(_components.li, null, "Be consistent within the complete form. This overrides preference on individual sections / pages of a form."), React.createElement(_components.li, null, "If the majority of form elements are required, only mark the optional fields."), React.createElement(_components.li, null, "If the majority of form elements are optional, only mark the required fields."), React.createElement(_components.li, null, "If there is a healthy mix, defer to the consistency of labeling in related user flows.")))), React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(Stack, {
    direction: "column",
    spacing: 4
  }, React.createElement(ImageBlock, {
    src: "/images/form/form_required.png"
  }), React.createElement(ImageBlock, {
    src: "/images/form/form_optional.png"
  })))), "\n", React.createElement(_components.h2, {
    id: "inline-help",
    style: {
      position: "relative"
    }
  }, "Inline Help", React.createElement(_components.a, {
    href: "#inline-help",
    "aria-label": "inline help permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.h3, {
    id: "use-sentence-case-capitalization",
    style: {
      position: "relative"
    }
  }, "Use sentence case capitalization", React.createElement(_components.a, {
    href: "#use-sentence-case-capitalization",
    "aria-label": "use sentence case capitalization permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(Stack, {
    spacing: 3,
    className: "m-t-4"
  }, React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(_components.h3, null, "Keep help text 1-2 lines as long as the element itself"), React.createElement(BodyText, null, "Most contexts for forms require a label. Some exceptions include when an icon can clearly explain the use case, such as a search input, or if a label exists elsewhere, such as a table column.")), React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(ImageBlock, {
    src: "/images/form/form_inline_text.png",
    suggest: "do"
  }), React.createElement(Banner, {
    status: "success",
    title: "Do",
    className: "m-b-4"
  }), React.createElement(ImageBlock, {
    src: "/images/form/form_inline_text_long.png",
    suggest: "dont"
  }), React.createElement(Banner, {
    status: "critical",
    title: "Don't"
  }))), "\n", React.createElement(Stack, {
    spacing: 3,
    className: "m-t-4"
  }, React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(_components.h3, null, "Is used for a character counter")), React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(ImageBlock, {
    src: "/images/form/form_inline_character.png"
  }))), "\n", React.createElement(_components.h3, {
    id: "model-text-inputs",
    style: {
      position: "relative"
    }
  }, "Model text inputs", React.createElement(_components.a, {
    href: "#model-text-inputs",
    "aria-label": "model text inputs permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Modeled text inputs are text field inputs that require text to be formatted in a specific way. Because modeled text inputs require a particular structure, always include examples that demonstrate how the user should enter the information."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Use help text to include an instructional call to action and an example that shows the required text format"), "\n", React.createElement(_components.li, null, "If there’s not enough room to include both an instructional call to action and an example, then include only the example"), "\n", React.createElement(_components.li, null, "Use the word “Example” followed by a colon to introduce the example (instead of e.g.)"), "\n"), "\n", React.createElement(Stack, {
    spacing: 3
  }, React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(ImageBlock, {
    src: "/images/form/form_model_text_1_do.png",
    suggest: "do"
  }), React.createElement(Banner, {
    status: "success",
    title: "Do"
  })), React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(ImageBlock, {
    src: "/images/form/form_model_text_1_dont.png",
    suggest: "dont"
  }), React.createElement(Banner, {
    status: "critical",
    title: "Don't"
  }))), "\n", React.createElement(Stack, {
    spacing: 3,
    className: "m-t-4"
  }, React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(ImageBlock, {
    src: "/images/form/form_model_text_2_do.png",
    suggest: "do"
  }), React.createElement(Banner, {
    status: "success",
    title: "Do"
  })), React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(ImageBlock, {
    src: "/images/form/form_model_text_2_dont.png",
    suggest: "dont"
  }), React.createElement(Banner, {
    status: "critical",
    title: "Don't"
  }))), "\n", React.createElement(_components.h2, {
    id: "overlay-help",
    style: {
      position: "relative"
    }
  }, "Overlay Help", React.createElement(_components.a, {
    href: "#overlay-help",
    "aria-label": "overlay help permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(Stack, {
    spacing: 3,
    className: "m-t-4"
  }, React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(_components.h3, null, "Keep help text 1-2 lines"), React.createElement(BodyText, null, "This is true even in a form with lots of overlay help. Either find unique supplemental information to discuss, or remove the overlay help.")), React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(ImageBlock, {
    src: "/images/form/form_overlay_lines.png"
  }))), "\n", React.createElement(Stack, {
    spacing: 3,
    className: "m-t-4"
  }, React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(_components.h3, null, "Use a Popover for longer content or one with links"), React.createElement(BodyText, null, "This is true even in a form with lots of overlay help. Either find unique supplemental information to discuss, or remove the overlay help.")), React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(ImageBlock, {
    src: "/images/form/form_overlay_popover.png"
  }))), "\n", React.createElement(Stack, {
    spacing: 3,
    className: "m-t-4"
  }, React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(_components.h3, null, "Don’t repeat the content of the label"), React.createElement(BodyText, null, "This is true even in a form with lots of overlay help. Either find unique supplemental information to discuss, or remove the overlay help.")), React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(ImageBlock, {
    src: "/images/form/form_overlay_dont_repeat.png",
    suggest: "dont"
  }), React.createElement(Banner, {
    status: "critical",
    title: "Don't"
  }))), "\n", React.createElement(Stack, {
    spacing: 3,
    className: "m-t-4"
  }, React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(_components.h3, null, "Avoid surfacing essential information"), React.createElement(BodyText, null, "Users generally have to recall overlay information when completing the form element. Inline help lets the user read it at all times.")), React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(ImageBlock, {
    src: "/images/form/form_overlay_nonessential.png",
    suggest: "dont"
  }), React.createElement(Banner, {
    status: "critical",
    title: "Don't"
  }))), "\n", React.createElement(_components.h2, {
    id: "placeholder-text",
    style: {
      position: "relative"
    }
  }, "Placeholder Text", React.createElement(_components.a, {
    href: "#placeholder-text",
    "aria-label": "placeholder text permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.h3, {
    id: "in-general-avoid",
    style: {
      position: "relative"
    }
  }, "In general, avoid", React.createElement(_components.a, {
    href: "#in-general-avoid",
    "aria-label": "in general avoid permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "In most use cases, the inline and overlay help are preferred picks to placeholder text. Placeholder text is low contrast, it disappears as soon as a user starts typing, is limited in space, and unreliable for screen readers."), "\n", React.createElement(Stack, {
    spacing: 3,
    className: "m-t-4"
  }, React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(_components.h3, null, "Don’t use real examples"), React.createElement(BodyText, null, "Real examples confuse user input. If an example is used, it should look clearly generic.")), React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(ImageBlock, {
    src: "/images/form/form_placeholder_no_real.png",
    suggest: "dont"
  }), React.createElement(Banner, {
    status: "critical",
    title: "Don't"
  }))), "\n", React.createElement(Stack, {
    spacing: 3,
    className: "m-t-4"
  }, React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(_components.h3, null, "In empty Selects, can describe the action taken"), React.createElement(BodyText, null, "This would be typically be worded as “Select ___”")), React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(Stack, {
    direction: "column",
    spacing: 4
  }, React.createElement(ImageBlock, {
    src: "/images/form/form_placeholder_select_1.png"
  }), React.createElement(ImageBlock, {
    src: "/images/form/form_placeholder_select_2.png"
  }), React.createElement(ImageBlock, {
    src: "/images/form/form_placeholder_select_empty.png"
  })))), "\n", React.createElement(_components.h1, {
    id: "related-content",
    style: {
      position: "relative"
    }
  }, "Related Content", React.createElement(_components.a, {
    href: "#related-content",
    "aria-label": "related content permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.h2, {
    id: "components",
    style: {
      position: "relative"
    }
  }, "Components", React.createElement(_components.a, {
    href: "#components",
    "aria-label": "components permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "For working with the component directly, refer to the ", React.createElement(_components.a, {
    href: "/components/form"
  }, "component page version of Forms"), "."), "\n", React.createElement(_components.h2, {
    id: "patterns",
    style: {
      position: "relative"
    }
  }, "Patterns", React.createElement(_components.a, {
    href: "#patterns",
    "aria-label": "patterns permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "For how to handle errors in Forms, refer to the ", React.createElement(_components.a, {
    href: "/patterns/errors/"
  }, "Errors and Warnings"), " pattern."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "For how to handle notifications in Forms that aren't errors, refer to the ", React.createElement(_components.a, {
    href: "/patterns/notification/"
  }, "Notifications"), " pattern."), "\n"), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
